module.exports = [{
      plugin: require('/home/circleci/project/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/project/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1100,"quality":90,"linkImagesToOriginal":true},
    },{
      plugin: require('/home/circleci/project/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-135385436-1"},
    },{
      plugin: require('/home/circleci/project/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
